<template>
  <div>
    <div v-if="$vuetify.breakpoint.xs">
      <v-bottom-sheet
        v-model="mergeField"
        content-class="custom-bottom-sheet"
        min-height="80vh"
        height="80vh"
      >
        <v-row no-gutters class="drawer-wrapper p-0">
          <v-col cols="12" class="drawer-header-mobile">
             <div class="merge-field-title">
              <span></span>
              <span class="drawer-heading">Merge Fields</span>
            <div class="close-icon" @click="onCloseClick">
              <HbIcon mdi-code="mdi-close" color="white" />
            </div>
             </div>
            <div class="px-4 py-4">
              <HbTextField
                search
                v-model="mergeSearch"
                v-validate="'required|max:45'"
                data-vv-scope="default"
                data-vv-name="text-field-search"
                data-vv-as="Text Field Search"
                @click:clear="clearSearch"
                :error="errors.has('default.text-field-search')"
                placeholder="Search Merge Fields"
              />
            </div>
            <div class="mb-4">
              <v-divider class="mt-2 mb-2"></v-divider>
              <h3 class="mx-6 mt-4 mb-8">All Merge Fields</h3>
            </div>
          </v-col>
          <v-col cols="12" class="drawer-content-mobile">
            <ul class="list-view-mobile">
              <!-- Iterate over merge fields -->
              <li
                v-for="(field, index) in drawerFilterFields"
                :key="index"
                class="list-item"
                @click="insertMergeField(field.token)"
              >
                <div class="mt-3 mb-3 hb-text-light">{{ field.token }}</div>
                <div class="tooltip-label">
                  <hb-tooltip>
                    <template v-slot:item>
                      <div class="plus-icon">
                        <HbIcon mdi-code="mdi-information" small />
                      </div>
                    </template>
                    <template v-slot:body>
                      {{ field.label }}
                    </template>
                  </hb-tooltip>
                </div>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-bottom-sheet>
    </div>
    <div v-else-if="!$vuetify.breakpoint.xs && triggerMergeFields">
      <vue-draggable-resizable
        :active="true"
        :parent="false"
        :w="w"
        :h="h"
        :x="centerX"
        :y="centerY"
        :min-height="250"
        :z="99999"
        class-name="vdr-custom"
        :prevent-deactivation="preventDeactivation"
        :draggable="true"
        :onDrag="onDrag"
        drag-handle=".drag-wrapper-add-merge-field"
        :resizable="false"
        class="draggable-section"
      >
        <v-row no-gutters class="drag-wrapper drag-wrapper-add-merge-field">
          <v-col rounded class="header">
            <HbIcon
              class="drag-icon"
              mdi-code="mdi-drag-custom"
              color="white"
            />
            <span class="text-heading">Merge Fields</span>
            <div class="close-icon" @click="onCloseClick">
              <HbIcon mdi-code="mdi-close" color="white" />
            </div>
          </v-col>
          <v-col class="content">
            <div>
              <div class="pa-5">
                <HbSelect
                  box
                  v-model="selectCategory"
                  :items="[
                    'All Merge Fields',
                    'Tenant',
                    'Facility',
                    'Space',
                    'Reversal Space',
                  ]"
                  :clearable="false"
                  v-validate="'required|max:45'"
                  data-vv-scope="default"
                  data-vv-name="phone"
                  data-vv-as="Phone"
                  :error="errors.has('default.phone')"
                />
                <HbTextField
                  class="mt-3"
                  search
                  v-model="mergeSearch"
                  v-validate="'required|max:45'"
                  data-vv-scope="default"
                  data-vv-name="text-field-search"
                  data-vv-as="Text Field Search"
                  @click:clear="clearSearch"
                  :error="errors.has('default.text-field-search')"
                  placeholder="Search Merge Fields"
                />
              </div>
              <v-divider class="mt-2 mb-2"></v-divider>
              <ul class="list-view">
                <!-- Iterate over the groups -->
                <li
                  v-for="(fields, category) in filteredFields"
                  :key="category"
                >
                  <!-- Render category heading -->
                  <h3 class="mx-6 mt-2 category">{{ category }}</h3>
                  <!-- Iterate over fields within each category -->
                  <ul class="ul-list" v-if="fields">
                    <li
                      v-for="(field, index) in fields"
                      :key="index"
                      class="drag-item"
                      @click="insertMergeField(field.token)"
                    >
                      <div class="mt-3 mb-3 hb-text-light pr-2 break-word">
                        {{ field.token }}
                      </div>
                      <div class="tooltip-label">
                        <hb-tooltip>
                          <template v-slot:item>
                            <div class="plus-icon">
                              <HbIcon mdi-code="mdi-information" small />
                            </div>
                          </template>
                          <template v-slot:body>
                            {{ field.label }}
                          </template>
                        </hb-tooltip>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </vue-draggable-resizable>
    </div>
  </div>
</template>

<script>
import { MergeFields } from "../settings/CommunicationTemplates/TemplateManager/MergeFields";
import VueDraggableResizable from "vue-draggable-resizable";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "AddMergeFields",
  data() {
    return {
      centerX: 0,
      centerY: 0,
      mergeSearch: this.filterInput,
      allMergeFields: MergeFields.mergeFields,
      selectCategory: "All Merge Fields",
      mergeFields: MergeFields.mergeFields,
      config: {
        type: "email",
      },
    };
  },
  computed: {
    mergeField:{
      get() {
        return this.triggerMergeFields
      },
      set(value) {
         this.setTriggerMergeFieldValue(value)
      }
    },
    drawerFilterFields() {
      const searchQuery = (this.mergeSearch || "").trim().toLowerCase();
      const filtered = this.allMergeFields.filter(
        (field) =>
          field.label.toLowerCase().includes(searchQuery) ||
          field.label.toLowerCase().includes(searchQuery)
      );
      return filtered;
    },
    groupedFields() {
      const grouped = {};
      // Group mergeFields by category
      this.mergeFields.forEach((field) => {
        const category = field.token.split(".")[0].substring(1);
        if (!grouped[category]) {
          grouped[category] = [];
        }
        grouped[category].push(field);
      });
      return grouped;
    },
    filteredFields() {
      const filtered = {};
      const searchQuery = (this.mergeSearch || "").trim().toLowerCase();
      const categoryFilter = this.selectCategory.replace(/\s+/g, "");
      Object.keys(this.groupedFields).forEach((category) => {
        if (
          categoryFilter === "AllMergeFields" ||
          category === categoryFilter
        ) {
          this.groupedFields[category].forEach((field) => {
            if (field.token.toLowerCase().includes(searchQuery)) {
              if (!filtered[category]) {
                filtered[category] = [];
              }
              filtered[category].push(field);
            }
          });
        }
      });
      return filtered;
    },
    ...mapGetters({
      triggerMergeFields: "addMergeFieldsStore/triggerMergeFields",
      filterInput: "addMergeFieldsStore/filterInput",
    }),
  },
  components: {
    VueDraggableResizable,
  },
  methods: {
    ...mapActions({
      setSelectedMergeField: "addMergeFieldsStore/setSelectedMergeField",
      setFilterInput: "addMergeFieldsStore/setFilterInput",
      resetMergeFieldParameters: "addMergeFieldsStore/reset",
      setTriggerMergeFieldValue: "addMergeFieldsStore/setTriggerMergeFieldValue"
    }),
    onDrag(x,y){
      if(x < window?.scrollX || y < window?.scrollY || x > window?.scrollX + window?.innerWidth - this.w || y > window?.scrollY + window?.innerHeight - 492){
        return false
      }
    },
    clearSearch() {
      this.mergeSearch = "";
    },
    insertMergeField(token) {
      this.setSelectedMergeField({
        selectedMergeField: token,
      });
    },
    onCloseClick() {
      this.mergeSearch = ''
      this.selectCategory  = "All Merge Fields"
      this.resetMergeFieldParameters()
    },
  },
  props: {
    className: {
      type: String,
      default: "vdr",
    },
    preventDeactivation: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    parent: {
      type: Boolean,
      default: true,
    },
    w: {
      type: Number,
      default: 300,
    },
    h: {
      type: Number,
      default: 300,
    },
    context: {
      type: String,
    },
  },
  watch: {
    filterInput(newVal) {
      this.mergeSearch = newVal;
    },
    triggerMergeFields(newVal) {
      if (newVal) {
        // Vertical center
        this.centerY = window?.scrollY + window?.innerHeight / 2 - 492 / 2;
        // Horizontal center
        this.centerX = window?.scrollX + window?.innerWidth / 2 - this.w / 2;
      }
    },
  },
};
</script>

<style scoped>
.ul-list {
  padding: 0 !important;
}
.break-word {
  word-break: break-word;
}

.plus-icon {
  cursor: pointer;
}

.plus-icon > span > span > i {
  font-size: 18px !important;
}

.drawer-wrapper {
  background-color: white !important;
  display: flex;
  height: 100%;
}

.drawer-header {
  height: 40px;
  background: var(--Primary-Brand-Darker, #003135);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
}

.drawer-heading {
  color: #ffffff;
  font-size: medium;
}

.drawer-content {
  margin-top: 12px;
  overflow-x: hidden;
}

.drawer-content-mobile {
  height: 80%;
  overflow-y: auto;
  margin-top: 12px;
  overflow-x: hidden;
  margin-bottom: 12px;
}

.content-class {
  background: rgba(250, 251, 252, 1);
}

.category {
  color: var(--Primary-Brand-Darker, #003135);
  font-family: Graphik;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}

.section-content {
  height: max-content;
  margin-bottom: 49px !important;
}

.drag-item {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 24px;
}

:hover.drag-item {
  background: var(--Primary-Cloud-Lighter, #f9fafb);
}

:active.drag-item {
  background: var(--Primary-Brand-Lighter, #e0f5f5);
}

.drag-icon {
  padding: 8px 0px 8px 12px;
}

.close-icon {
  margin-right: 16px;
  cursor: pointer;
}

.list-view {
  list-style: none;
  overflow-y: auto;
  overflow-x: hidden;
  padding: inherit;
  height: 300px;
}
.list-view-mobile {
  list-style: none;
  overflow-y: auto;
  overflow-x: hidden;
  padding: inherit;
}
.drawer-header-mobile {
  height: 20%;
  position: sticky;
}


.list-item {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 24px;
}

:hover.list-item {
  background: var(--Primary-Cloud-Lighter, #f9fafb);
}

.vdr-custom {
  position: absolute;
}

.drag-wrapper {
  border: 3px solid;
  border-color: var(--Primary-Brand-Darker, #003135);
  border-radius: 10px;
  overflow: hidden;
}

.content {
  background-color: #ffffff;
  margin-top: 40px;
}

:active.list-item {
  background: var(--Primary-Brand-Lighter, #e0f5f5);
}

.tooltip-label {
  padding-right: 14px;
}

.header {
  border-radius: 8px 8px 0px 0px;
  height: 40px;
  /* Fixed header height */
  background: var(--Primary-Brand-Darker, #003135);
  cursor: move;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
  cursor: all-scroll;
}
.merge-field-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  background: var(--Primary-Brand-Darker, #003135);
}
</style>
