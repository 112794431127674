<template>
    <v-row 
        class="border-bottom ma-0 px-2" 
        :class="{'blurred-row' : task.task.completed}"
        style="height: auto;">
        
        <v-col cols="7"  class="pr-0 d-flex" style=" flex-direction: column;">
            <div style="flex: 1">
                <div class="col-12 pa-0">
                    <div class="list-title pb-1 d-flex align-center">
                        <hb-icon class="pr-2" :class="task.EventType.slug" color="#101318">{{ task.EventType.icon }}</hb-icon>{{ task.EventType.name }}
                    </div>
                    <p class="text-subtitle-2 ellipse-text">{{ task.EventType.text }} </p>
                </div>
            </div>
            <div class="created-by-name" v-if="task.task.created_by_contact">
                Created By: {{ task.task.created_by_contact }}
            </div>
             <div style="flex: 0" > 
                <div class="d-flex align-center">
                    <a class="font-weight-medium d-flex align-center hb-link-secondary" v-if="unit.id" @click.stop="viewEntity('unit', unit.id)">
                        <div class="pl-1 pr-1">
                            <HbUnitIcon small :type="getUnitTypeLabel(unit)" color="#306FB6" />
                        </div>
                        <div class="font-weight-medium">
                            #{{ unit.number }}
                        </div>
                    </a>
                    <a class="font-weight-medium d-flex align-center hb-link-secondary" v-if="task.Contact.id" @click.stop="viewEntity('contact', task.Contact.id)" :class="{'ml-3' : unit.id }">
                        <div class="pr-1 pb-1">
                            <HbIcon v-if="task.Contact.rent_as_business" color="#306FB6" small mdi-code="mdi-domain" />
                            <HbIcon v-else color="#306FB6" small mdi-code="mdi-account" />
                        </div>
                        <div class="font-weight-medium">
                            {{task.Contact.first}} {{task.Contact.last}}
                        </div>
                    </a>
                </div>
             </div>
        </v-col>
        <v-col cols="5" class="text-right px-2 d-flex" style=" flex-direction: column;" >

            <div style="flex: 1" class="text-right">
                
                
                <div :style="{color: timeColor}" class="follow-timer pb-4" v-if="task.EventType.urgency_timer"> 
                    <span >Follow Up Timer</span><br/>
                    <span class="hb-default-font-size font-weight-medium">{{ duration }}</span>
                </div>
                <div class="move-out-date pb-4" v-else-if="task.task.created_at">
                    {{ task.task.created_at | formatDate}}
                    <hb-tooltip v-if="!overLockRemoveOverlock && !scheduleAuction">
                        <template v-slot:item>
                            <hb-icon class="pl-1" pointer small color="secondary" @click="dismiss">mdi-close</hb-icon>
                        </template>
                        <template v-slot:body>
                            Dismiss This Task
                        </template>
                    </hb-tooltip>
                </div>
                <div class="move-out-date pb-4" v-else-if="task.EventType.can_dismiss"> 
                    <hb-link color="secondary" text v-if="task.EventType.can_dismiss" small @click="dismiss"><hb-icon small color="secondary">mdi-close</hb-icon></hb-link>   
                </div>
                
                
            </div>
            <div style="flex: 0 1 " class="text-right undo-button">    
                        
                <!-- <hb-btn color="secondary" v-if="task.Event && task.Event.upload_id" small @click.native.stop="downloadFile"><v-icon>mdi-download</v-icon></hb-btn> -->
                <hb-btn
                    :disabled="loading"
                    :loading="loading"
                    color="primary"
                    small
                    @click="moveTo"
                    v-show="!task.task.completed"
                    :append-icon="task && task.EventType && task.EventType.link_type && task.EventType.link_type === 'external' ? 'mdi-launch' : ''"
                    append-icon-more-padding
                >
                    {{ task && task.EventType && task.EventType.btn_text && task.EventType.btn_text.length > 0 ? task.EventType.btn_text : 'Complete' }}
                </hb-btn>
                <hb-btn v-show="task.task.completed && task.EventType.can_dismiss" color="destructive" small @click="markIncomplete"><strong>Undo</strong></hb-btn>
            </div>
        </v-col>

    </v-row>
</template>

<script>
    import moment from 'moment';
    import { mapGetters, mapActions } from 'vuex';
    import { EventBus } from '../../../EventBus.js';
    import api from '../../../assets/api.js';

    export default {
        name: "TaskItem",
        props: [  'index', 'task'],
        data() {
            return {
                duration: '',
                interval: '',
                timeColor: '', 
                
                loading: false
            }
        },
        computed:{
            ...mapGetters({
                properties: 'propertiesStore/filtered',
                eventTypes: 'taskCenterStore/eventTypes'
            }),
            btnText() {
                return this.task.EventType.btn_text !== 'See List' && this.task.EventType.name !== 'Move Out' ? this.task.EventType.btn_text : this.task.EventType.name === 'Move Out' ? 'Move-Out' : 'Follow-Up';
            },
            unit (){
                return this.task.Lease && this.task.Lease.Unit ? this.task.Lease.Unit : {}
            }, 
            eventTypeTimeStamp(){
                return this.task.task.created_at

                // if (this.todo && this.todo.Event && this.todo.Event.created_at) {
                //     return this.todo.Event.created_at;
                // } else if (this.task.Event.task_type === 'moveIn' && this.task.Event.created) {
                //     return this.task.Event.created;
                // }
                // return ''
            },
            eventStartDate(){
                if(this.todo && this.todo.Event && this.todo.created_at){
                    return this.todo.created_at;
                }
            },
            overLockRemoveOverlock(){
                return this.task.EventType.slug === 'overlock_space' || this.task.EventType.slug === 'lock_removal';
            },
            scheduleAuction(){
                return this.task.EventType.slug === 'cut_lock_schedule_auction';
            },            
        },
        mounted() {
          
            if(this.task.EventType.urgency_timer && this.task.task.created_at){
                if (this.task.task.completed) {
                    this.calculateTimer(true)
                } else {
                    this.interval = setInterval(() => this.calculateTimer(), 1000);
                }
            }
        },

        created(){
            // this.setUpEvent();
        }, 

        methods: {
            ...mapActions({
                markTask:  'taskCenterStore/markTask',
                setTaskCenterOpen: 'taskCenterStore/setTaskCenterOpen',
                actionFollowUpStatus:"onBoardingStore/actionFollowUpStatus",
            }), 
            setUpEvent(){



                if(this.task.EventType.slug === 'pending_transfer'){
                    this.$set(this.task.EventType, 'icon_style', {
                        verticalAlign: "middle",
                        display: "inline-block",
                        marginTop: "-3px",
                    })
                    // this.task.EventType.icon_style= {
                    //     verticalAlign: "middle",
                    //     display: "inline-block",
                    //     marginTop: "-3px",
                    // }
                    this.task.EventType.link_type = 'external';
                } else if(this.task.EventType.slug === 'pending_move_in'){
                    this.task.EventType.link_type = 'external';
                } else if(this.task.EventType.slug === 'task' ){
                    this.task.EventType.task_type = 'task';
                    this.task.EventType.link_type = this.getLinkType(this.task.EventType); 
                } else {
                    this.task.EventType.link_type = this.getLinkType(this.task.EventType); 
                }
                
            }, 
            dismiss(){
                this.$emit("dismissTask", this.task.task)
            }, 
            complete(){
                this.$emit("completeTask", this.task.task)
            }, 
            markIncomplete(){
                this.$emit("markIncomplete", this.task.task)
            }, 
            byType(task){
                let types = this.task.EventTypes.filter(type => {                    
                    if(type.action === task.title){
                        return type;
                    }
                });
                return types;
            },
            getDate(value){
                return moment(value).format('MMM DD, YYYY')
            },
            getIcon(value){
                let icon = '';
                if (value && value.includes('Google Icon:')) {
                    icon = value.replace('Google Icon:', '')
                }
                return icon;
            },
            async downloadFile() {
                const downloadedFile = await api.get(this, api.UPLOADS + `${this.todo.Event.upload_id}/download`);
                const fileContent = downloadedFile.file;
                var buffer = Buffer.from(fileContent.Body.data);
                var blob = new Blob([buffer], { type: downloadedFile.mimetype });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = downloadedFile.name;
                link.click();
            },
            getLinkType(event_type){
                    
                    switch (event_type.slug) {
                        case 'new_lead':
                        case 'new_web_lead':
                        case 'cart_abandonment':
                        case 'coupon_clicks':
                        case 'hotlead':
                        case 'reservation_failure' :
                        case 'lead_follow_up':
                        case 'new_reservation':
                        case 'new_web_reservation':
                        case 'reservation_follow_up':
                        case 'collection_calls':
                        case 'move_out':
                        case 'move_out_cancelled':
                        case 'move_out_confirmation':
                        case 'review_rate_change_notification':
                        case 'approve_rate_change':
                        case 'generated_rate_change_documents':
                            return 'external';
                            break;
                        case 'lock_removal':
                        case 'overlock_space':
                        case 'move_out':
                        case 'auction_day':
                        case 'auction_payment':
                        case 'cut_lock_schedule_auction':
                            return null

                    }
            }, 
            async moveTo(){
                if (this.task.EventType.slug === 'pending_transfer' ) {
                    this.loading = true;
                    
                    await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === this.unit.property_id));
                    await this.$store.dispatch('onBoardingStore/getPending', { unit_id: this.unit.id });
                    let data = {
                        lease_id: this.task.Lease.Transfer.from_lease_id,
                        contact: {id: this.task.Contact.id},
                        unit_id: this.unit.id,
                        transfer: this.task.Lease.Transfer,
                        property_id: this.unit.property_id
                    };
                    EventBus.$emit('transfer', data);
                    this.setTaskCenterOpen(false);
                } else if (this.task.EventType.slug === 'pending_move_in') {
                    this.loading = true;
                    this.actionFollowUpStatus(true);
                    await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === this.unit.property_id));
                    await this.$store.dispatch('onBoardingStore/getPending', { unit_id: this.unit.id });

                    EventBus.$emit('new_lead');
                    this.setTaskCenterOpen(false);
                } else if (this.task.EventType.slug === 'task') {
                    this.complete();
                } else {
                    this.loading = true;
                    switch (this.task.EventType.slug) {
                        case 'new_lead'             :
                        case 'new_web_lead'         :
                        case 'cart_abandonment'     :
                        case 'coupon_clicks'        :
                        case 'hotlead'              :
                        case 'reservation_failure'  :
                        case 'lead_follow_up'       :
                        case 'new_reservation'      :
                        case 'new_web_reservation'  :
                        case 'reservation_follow_up':
                            if (this.task.EventType.slug === 'new_web_lead' || this.task.EventType.slug === 'new_web_reservation' || this.task.EventType.slug === 'new_reservation' || this.task.EventType.slug === 'new_lead' || this.task.EventType.slug === 'cart_abandonment' || this.task.EventType.slug === 'coupon_clicks' || this.task.EventType.slug === 'reservation_failure' || this.task.EventType.slug === 'hotlead') {
                                let event = {
                                    id: this.task.task.id,
                                    time: this.task.task.created_at
                                }   
                                this.fetchData(event);
                            } else {
                                this.fetchData();
                            }
                            break;
                        case 'collection_calls':
                            let contactData = this.task.Contact;
                            let taskData = this.task;
                            contactData.lease_id = this.task.Lease.id;
                            contactData.property_id =  this.task.Lease?.Unit?.property_id; 
                            let allData = { ...contactData, ...taskData };
                            EventBus.$emit('HB-Navigation:DelinquencyCenter', allData);
                            break;
                        case 'lock_removal':
                            await this.$store.dispatch('taskCenterStore/removeOverlock', { unit_id: this.unit.id, lease_id: this.task.Lease.id });
                            this.markTask({task_id: this.task.task.id, complete: 1}); 
                            EventBus.$emit('fetch_unit_gate_status'); 
                            break;
                        case 'overlock_space':
                            await this.$store.dispatch('taskCenterStore/overlockSpace', { unit_id: this.unit.id, lease_id: this.task.Lease.id });
                            this.markTask({task_id: this.task.task.id, complete: 1});
                            EventBus.$emit('fetch_unit_gate_status');
                            break;
                        case 'move_out':
                        case 'move_out_cancelled':
                        case 'move_out_confirmation':
                            let data = {
                                property_id: this.unit.property_id,
                                lease_id: this.task.Lease.id,
                                contact: this.task.Contact,
                                unit_id:  this.unit.id,
                                move_out_date: this.task.task.start_date
                            };
                            EventBus.$emit('move_out', data);
                            break;
                        case 'review_rate_change_notification':
                        case 'approve_rate_change':
                            let rateData = {
                                rateChangeType: this.task.Event.Rate_Change.reviewed ? 'finalize-rate-change' : 'review-rate-change',
                                rateChangeItem : this.task.Event.Rate_Change,
                            }
                            EventBus.$emit('rate_change', rateData);
                            break;
                        case 'move_out':
                        case 'auction_day':
                        case 'auction_payment':
                            EventBus.$emit('HB-Navigation:AuctionManager', this.task.Lease, this.task.Contact.id, 3);
                            break;
                        case 'cut_lock_schedule_auction': 
                            EventBus.$emit('HB-Navigation:AuctionManager', this.task.Lease, this.task.Contact.id, 0);
                            break;
                        case 'auction_notice_advertisement':
                        case 'space_inventory':
                            // this.complete();
                            EventBus.$emit('HB-Navigation:AuctionManager', this.task.Lease, this.task.Contact.id, 1);
                            break;
                        case 'lock_cut':
                            this.complete();
                            break;
                        case 'generated_rate_change_documents':
                            EventBus.$emit('download_center')
                            break
                        default:
                            break;
                    }
                    this.loading = false;
                }

            },

            fetchData(event = null){
                
                api.get(this, api.CONTACTS + this.task.Contact.id).then(r => {
                    var contact = r.contact;
                    var data = {
                        contact_id: contact.id,
                        lead_first: contact.first,
                        lead_last: contact.last
                    }

                    if(contact.Lead){
                        data.lead_time = contact.Lead.created;
                        data.id = contact.Lead.id;
                    }

                    if(this.unit && contact.Reservations){
                        contact.Reservations.forEach( reservation => {
                            if (reservation.Lease && reservation.Lease.Unit && reservation.Lease.Unit.id == this.unit.id) {
                                data.expires = reservation.expires;
                                data.lease_id = reservation.Lease.id;
                                data.reservation_id = reservation.id;
                                data.reservation_time = reservation.created;
                                data.unit_id = reservation.Lease.Unit.id;
                                data.property_id = reservation.Lease.Unit.property_id
                            }
                        });
                    }
                    data.event = event;
                    data.selectedLeadId = this.task.Lease?.Unit?.Lease?.Lead?.id;
                    // setting follow up status to true as the lead is being followed up from task center 
                    this.actionFollowUpStatus(true)
                    EventBus.$emit('HB-Navigation:LeadFollowUp', data);
                });
            },
            calculateTimer(completedAt = false) {

                let startDate = moment.utc(this.task.task.created_at).local();
                let endDate = completedAt ? moment.utc(this.task.task.completed_at).local() : moment();

                let diffInMilliSeconds = Math.abs(endDate - startDate) / 1000;

                const days = Math.floor(diffInMilliSeconds / 86400);
                diffInMilliSeconds -= days * 86400;

                let hours = (Math.floor(diffInMilliSeconds / 3600) % 24)
                diffInMilliSeconds -= hours * 3600;

                const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
                diffInMilliSeconds -= minutes * 60;

                const seconds = Math.floor(diffInMilliSeconds) % 60;

                let difference = '';
                // if (days > 0) {
                //   difference += days.toString().padStart(2, '0') + ':'
                // }
                hours += 24 * days;
                difference += hours.toString().padStart(2, '0') + ':'
                difference += minutes.toString().padStart(2, '0') + ':'
                difference += seconds.toString().padStart(2, '0')

                if (hours >= 48) {
                    this.timeColor = '#FB4C4C';
                } else if (hours > 0 || minutes >= ( this.task.EventType.urgency_time_limit ? this.task.EventType.urgency_time_limit : 5 )) {
                    this.timeColor = '#ffa300';
                } else if (minutes <= (this.task.EventType.urgency_time_limit ? this.task.EventType.urgency_time_limit : 5) && minutes >= 0) {
                    this.timeColor = '#02AD0F';
                } else {
                    this.timeColor = '#101318';
                }

                this.duration = difference;

            },
            getDetails(type){
                let task = this.todo;
                if (task && task.EventType) {
                    if (type == 'name') {
                        return task.EventType.name;
                    } else {
                        return task.details;
                    }
                }
            },
            viewEntity(entity, entity_id){
                let params = {};
                params = {
                    component: entity,
                    props: {}
                };
                params.props[entity + '_id'] = entity_id;
                this.$store.dispatch('navigationStore/openSlideOut', params);
            },
        },
        watch:{
        
        }, 
        beforeDestroy() {
            clearInterval(this.interval);
        },
    }
</script>

<style lang="scss" scoped>

    .created-by-name{
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #637381;
        margin-bottom: 0;
        padding-bottom: 5px;
    }

    .list-title{
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #101318;
    }
    .list-title:first-of-type + p {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #637381;
        margin-bottom: 0;
        padding-bottom: 5px;
    }
    .follow-timer{
        font-size: 11px;
        line-height: 16px;
        text-align: right;
    }
    .move-up-icon{
        position: relative;
        top: -3px;
    }
    .move-out-date{
        font-size: 12px;
        line-height: 16px;
        color: #637381;
    }
    .font-11px{
        font-size: 11px;
    }
    .ellipse-text{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .ellipse-text:hover{
        white-space: unset;
        overflow: auto;
        display: flex;
        align-items: baseline !important;
        overflow-wrap: anywhere;
    }
    .download-btn {
        float: right;
    }
    .list_icon{
        margin-top: 0px;
        vertical-align: middle;
    }
    .undo-button{
        z-index: 2000;
    }
</style>

<style>
.border-bottom{
    border-bottom: 1px solid #DFE3E8;
}
</style>
