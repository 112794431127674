// To add new merge fields, simply append new values to the arrays, following the existing structure.
// To add new merge fields, simply append new values to the arrays, following the existing structure.
// To add new merge fields, simply append new values to the arrays, following the existing structure.
// To add new merge fields, simply append new values to the arrays, following the existing structure.
// To add new merge fields, simply append new values to the arrays, following the existing structure.
// To add new merge fields, simply append new values to the arrays, following the existing structure.
// To add new merge fields, simply append new values to the arrays, following the existing structure.
// To add new merge fields, simply append new values to the arrays, following the existing structure.
// To add new merge fields, simply append new values to the arrays, following the existing structure.
export const MergeFields = {
  mergeFields: [
    { label: 'Payment Link', token: '[Tenant.PayLink]' },
    { label: 'Tenant First', token: '[Tenant.FirstName]' },
    { label: 'Tenant Last', token: '[Tenant.LastName]' },
    { label: 'Tenant Email Address', token: '[Tenant.Email]' },
    { label: 'Tenant Address 1', token: '[Tenant.Address1]' },
    { label: 'Tenant Address 2', token: '[Tenant.Address2]' },
    { label: 'Tenant City', token: '[Tenant.City]' },
    { label: 'Tenant State', token: '[Tenant.State]' },
    { label: 'Tenant Country', token: '[Tenant.Country]' },
    { label: 'Tenant Postal Code', token: '[Tenant.PostalCode]' },
    { label: 'Tenant Cell Phone', token: '[Tenant.CellPhone]' },
    { label: 'Tenant Past Due', token: '[Tenant.TotalPastDue]' },
    { label: 'Tenant Card Name', token: '[Tenant.CardName]' },
    { label: 'Tenant Card Number', token: '[Tenant.CardNumber]' },
    { label: 'Tenant Card Exp', token: '[Tenant.CardExpDate]' },
    { label: 'Tenant Billing Address 1', token: '[Tenant.BillingAddress1]' },
    { label: 'Tenant Billing Address 2', token: '[Tenant.BillingAddress2]' },
    { label: 'Tenant Billing City', token: '[Tenant.BillingCity]' },
    { label: 'Tenant Billing State', token: '[Tenant.BillingState]' },
    { label: 'Tenant Billing Country', token: '[Tenant.BillingCountry]' },
    { label: 'Insurance Premium Coverage', token: '[Insurance.PremiumCoverage]' },
    { label: 'Tenant Insurance Premium', token: '[Tenant.InsurancePremium]' },
    { label: 'Tenant Insurance Exp Date', token: '[Tenant.InsuranceExpDate]' },
    { label: 'Tenant Gate Code', token: '[Tenant.GateCode]' },
    { label: 'Tenant Latest Invoice', token: '[Tenant.LatestInvoice]' },
    { label: 'Tenant Latest Receipt', token: '[Tenant.LatestReceipt]' },
    { label: 'Tenant Alt Name', token: '[Tenant.AltName]' },
    { label: 'Tenant Alt Address 1', token: '[Tenant.AltAddress1]' },
    { label: 'Tenant Alt Address 2', token: '[Tenant.AltAddress2]' },
    { label: 'Tenant Alt City', token: '[Tenant.AltCity]' },
    { label: 'Tenant Alt State', token: '[Tenant.AltState]' },
    { label: 'Tenant Alt Country', token: '[Tenant.AltCountry]' },
    { label: 'Tenant Alt Postal Code', token: '[Tenant.AltPostalCode]' },
    { label: 'Tenant Business Rep Email', token: '[Tenant.BusinessRepresentativeEmail]' },
    { label: 'Tenant Business Rep Name', token: '[Tenant.BusinessRepresentativeName]' },
    { label: 'Tenant Business Rep Phone', token: '[Tenant.BusinessRepresentativePhone]' },
    { label: 'Tenant Business Rep Address 1', token: '[Tenant.BusinessRepresentativeAddress1]' },
    { label: 'Tenant Business Rep Address 2', token: '[Tenant.BusinessRepresentativeAddress2]' },
    { label: 'Tenant Business Rep City', token: '[Tenant.BusinessRepresentativeCity]' },
    { label: 'Tenant Business Rep State', token: '[Tenant.BusinessRepresentativeState]' },
    { label: 'Tenant Business Rep Country', token: '[Tenant.BusinessRepresentativeCountry]' },
    { label: 'Tenant Business Rep Postal Code', token: '[Tenant.BusinessRepresentativePostalCode]' },
    { label: 'Tenant Taxpayer ID', token: '[Tenant.TaxpayerID]' },
    { label: 'Facility Name', token: '[Facility.Name]' },
    { label: 'Facility Legal Name', token: '[Facility.LegalName]' },
    { label: 'Facility Address 1', token: '[Facility.Address1]' },
    { label: 'Facility Address 2', token: '[Facility.Address2]' },
    { label: 'Facility City', token: '[Facility.City]' },
    { label: 'Facility State', token: '[Facility.State]' },
    { label: 'Facility Country', token: '[Facility.Country]' },
    { label: 'Facility Postal Code', token: '[Facility.PostalCode]' },
    { label: 'Facility Phone', token: '[Facility.Phone]' },
    { label: 'Facility Email', token: '[Facility.Email]' },
    { label: 'Facility Website', token: '[Facility.Website]' },
    { label: 'Facility Late Fee', token: '[Facility.LateFee]' },
    { label: 'Facility Admin Fee', token: '[Facility.AdminFee]' },
    { label: 'Space Rate', token: '[Space.Rate]' },
    { label: 'Space ID', token: '[Space.ID]' },
    { label: 'Space Size', token: '[Space.Size]' },
    { label: 'Space Type', token: '[Space.Type]' },
    { label: 'Space Lock Code', token: '[Space.LockCode]' },
    { label: 'Space Lock Status', token: '[Space.LockStatus]' },
    { label: 'Space Lock Serial Number', token: '[Space.LockSerialNumber]' },
    { label: 'Space Overlock Code', token: '[Space.OverlockCode]' },
    { label: 'Space Overlock Status', token: '[Space.OverlockStatus]' },
    { label: 'Space Overlock Serial Number', token: '[Space.OverlockSerialNumber]' },
    { label: 'Tenant Move-In Date', token: '[Tenant.MoveInDate]' },
    { label: 'Tenant Move-In Cost', token: '[Tenant.MoveInCost]' },
    { label: 'Tenant Rent Due Date', token: '[Tenant.RentDueDate]' },
    { label: 'Tenant Rent', token: '[Tenant.Rent]' },
    { label: 'Tenant Promotions', token: '[Tenant.Promo]' },
    { label: 'Tenant Discounts', token: '[Tenant.Discount]' },
    { label: 'Tenant Rent With Tax', token: '[Tenant.RentWithTax]' },
    { label: 'Tenant New Rent With Tax', token: '[Tenant.NewRentWithTax]' },
    { label: 'Tenant Monthly Charges', token: '[Tenant.MonthlyCharge]' },
    { label: 'Tenant New Monthly Charges', token: '[Tenant.NewMonthlyCharge]' },
    { label: 'Reversal Spaces ID', token: '[ReversalSpace.ID]' }
  ]
};
