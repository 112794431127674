<template>
    <div>
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

        <merchandise
            v-if="active_tab === 'merchandise'"
            :merchandise="merchandise"
            :services.sync="services"
            :isLoadingProducts="isLoadingProducts"
            :property="property"
            @calculateTotal="$emit('calculateTotal')"
        />

        <fees
            v-if="active_tab === 'fees'"
            :property_id="property.id"
            :fees="fees"
            :lease_id="lease_id"
            :quick_action="quick_action"
            :services.sync="services"
            :isLoadingProducts="isLoadingProducts"
            @calculateTotal="$emit('calculateTotal')"
        ></fees>

        <div class="new-tenant-walkthrough-footer">
            <hb-bottom-action-bar
                @close="$emit('close')"
                :top-border="false"
            >
                <template v-slot:left-actions>
                    <help-juice />
                </template>
                <template v-slot:right-actions>
                    <hb-btn color="primary" :disabled="isLoading($options.name) || !canProgress || isCalculating" @click="productsSaved">{{ activeTab === 'fees' ? 'Payment' : 'Checkout' }}</hb-btn>
                </template>
            </hb-bottom-action-bar>
        </div>
    </div>

</template>

<script type="text/babel">
    import api from '../../../assets/api.js';
    import Status from './../Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import moment from 'moment';
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';
    import AsyncLoader from '../../includes/Loading.vue';
    import Insurance from './Insurance';
    import Merchandise from './Merchandise.vue';
    import Fees from './Fees';
    import { mapGetters, mapActions, mapMutations} from 'vuex'
    export default {
        name: 'ServicesSelection',
        mixins: [ notificationMixin ],
        components: {
            Status,
            Insurance,
            Merchandise,
            Fees,
            AsyncLoader
        },
        data: () => ({
            active_tab: 'merchandise',
            panel: 0,
            confirm: false,
            show_security_deposit: false,
            promotions:[],
            unit_id: '',
            products: [],
            isLoadingProducts: false
        }),
        props: ['property', 'services', 'lease_id', 'contact', 'quick_action', 'activeTab', 'isCalculating'],

        created(){
          if (this.property?.id) {
            this.fetchProducts();
          }

          if (this.activeTab) {
            this.active_tab = this.activeTab;
          }
        },
        computed:{
            ...mapGetters({
                activeDrawerComponent: 'navigationStore/activeDrawerComponent'
            }),
            canProgress(){
                for(let s in this.services){
                  if(this.services[s] && this.services[s].qty && this.services[s].price  ) return true;
                }
                return false;
            },
            merchandise(){
                let mappedProducts =  this.products.filter(p => p.default_type === 'product').map(p => {
                    p.qty = this.services[p.id]?.qty ? this.services[p.id].qty : 1;
                    p.price = this.services[p.id]?.price ? this.services[p.id].price : p.price;
                    return p;
                })
                return mappedProducts;
            },
            fees(){
                return this.products.filter(p => p.default_type === 'late').map(p => {
                    p.qty = this.services[p.id]?.qty ? this.services[p.id].qty : '';
                    return p;
                })
            }
        },
        methods:{
            ...mapMutations({
                setActiveDrawerComponent:'navigationStore/SET_ACTIVE_DRAWER_COMPONENT',
            }),
            async productsSaved(){
                if (this.property.id) {
                    if (Object.keys(this.services).length) {
                        this.services.skip = false
                        this.$emit('setServices', this.services);
                    } else {
                        this.showMessageNotification({ description: 'Please select at least one product' })
                    }
                } else {
                    this.showMessageNotification({ description: 'Please select property first' })
                }
            },
            async saveInvoice() {
                this.services.skip = true
                 this.$emit('setServices', this.services);
            },

            async fetchProducts(){
                this.isLoadingProducts = true;
                let type = this.activeTab === 'fees' ? 'late' : 'product&source=property_products';
                let r = await api.get(this, api.PROPERTIES + this.property.id + `/products?type=${type}`);
                try {
                    if (this.activeTab === 'fees') {
                        this.products = r.products;
                    } else {
                        let stockedProducts = r.products.filter(item => item.summary_inventory && item.summary_inventory.remaining_inventory);
                        stockedProducts = stockedProducts.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
                        let unstockedProducts = r.products.filter(item => !item.summary_inventory || !item.summary_inventory.remaining_inventory)
                        unstockedProducts = unstockedProducts.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
                        this.products = [...stockedProducts, ...unstockedProducts];
                        this.products = this.products.map(item => {
                            let images = item.merchandise_details?.Images && item.merchandise_details?.Images.length ? item.merchandise_details.Images : [];
                            let FoundImage = images.find(item => item.is_thumbnail);
                            FoundImage = !FoundImage ? images.length ? images[0] : '' : FoundImage;
                            let enableButton = false;
                            if ((item.summary_inventory && item.summary_inventory.remaining_inventory > 0) || item.negative_inventory) {
                                enableButton = true;
                            }
                            let outOfStock = false;                            
                            if(!item.negative_inventory && (!item.summary_inventory || (item.summary_inventory && item.summary_inventory.remaining_inventory < 1))){
                                outOfStock = true;
                            }

                            return {
                                id: item.id,
                                title: item.name,
                                description: item.description,
                                price: item.price,
                                out_of_stock: outOfStock,
                                stock: item.summary_inventory ? item.summary_inventory.remaining_inventory : '',
                                allow_buy: enableButton,
                                allow_negative: item.negative_inventory ? true : false,
                                image: FoundImage,
                                qty: item.default_type === 'product' ? 1 : '',
                                taxable:item.taxable ? true : false,
                                default_type: item.default_type,
                                type: item.type,
                                amount_type: item.amount_type || 'fixed'
                            }
                        });
                    }
                } catch (error) {
                    this.showMessageNotification({ description: error });
                } finally {
                    this.isLoadingProducts = false;
                }
            }
        },
        watch: {
            services(new_params, old_params) {
                const removedService = Object.keys(old_params).find(key => !new_params.hasOwnProperty(key));
                if (removedService) {
                    let foundProductIndex = this.products.findIndex(item => item.id === removedService && item.default_type === 'late');
                    if (foundProductIndex && this.products[foundProductIndex]) {
                        this.products[foundProductIndex].qty = 0 
                    }
                }
                this.setActiveDrawerComponent({ ...this.activeDrawerComponent, services: this.services});
            }
        },
    }
</script>

<style scoped>
    .tab-container{
      padding: 0 24px;
      background: none;
      border-bottom: 1px solid #E1E6EA
    }
    .new-tenant-walkthrough .v-tabs,
    .new-tenant-walkthrough .v-tabs .v-tabs-bar{
      background: #F9FAFB;
    }
    .new-tenant-walkthrough-footer{
        padding: 3px 0 3px 30px;
        background: #FFFFFF;
        border-top: 1px solid #e2e2e2;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 100;
        left: 0;
        right: 0;
    }
    .services-section{
        padding: 0px 24px 100px 24px;
    }

    .unit-confirm-icon .v-list-item__icon {
        text-align: center;
        background: white;
        padding: 25px;

    }

    .v-expansion-panel-content .form-label{
        background: #F4F6F8;
        padding: 20px;
        font-weight: bold;
    }
    .row {
        margin-bottom: 10px;
    }
</style>
